@charset 'UTF-8';
/* Arrows */
.slick-slider .slick-prev,
.slick-slider .slick-next {
  z-index: 10;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  color: rgba(0, 0, 0, 0);
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  opacity: 50%;
  cursor: auto;
}

.slick-slider .slick-prev {
  background-image: url("../img/l_arrow_large.svg");
  left: -25px;
}

.slick-slider .slick-next {
  background-image: url("../img/r_arrow_large.svg");
  right: -25px;
}

@media only screen and (max-width: 850px) {
  .slick-slider .slick-prev {
    left: 50px;
  }
  .slick-slider .slick-next {
    right: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .slick-slider .slick-prev,
  .slick-slider .slick-next {
    top: unset;
    bottom: -80px;
  }
  .slick-slider .slick-prev {
    left: calc(50% - 100px);
  }
  .slick-slider .slick-next {
    right: calc(50% - 100px);
  }
  .slick-arrow + .slick-list {
    margin-bottom: 80px;
  }
}

.slick-prev:not(.slick-disabled):hover,
.slick-prev:not(.slick-disabled):focus,
.slick-next:not(.slick-disabled):hover,
.slick-next:not(.slick-disabled):focus {
  transform: scale(1.3, 1.3) translateY(-37%);
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 15px;
}

.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  display: inline-block;
}
.slick-dots li button {
  position: relative;
  display: inline-block;

  width: 50px;
  height: 4px;
  margin: 10px;
  padding: 0;

  border-radius: 5px;
  background-color: lightgray;
  display: block;
  cursor: pointer;
  color: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  background-color: gray;
}
.slick-dots li.slick-active button {
  background-color: black;
}
