.page-fade-enter {
  opacity: 0;
}

.page-fade-enter.page-fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.page-fade-exit {
  display: none;
}
